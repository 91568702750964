import { MantineProvider, AppShell, Header, Footer, Loader, Center, Text, Alert, Box } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UploadComponent from 'uploads-component/dist/cjs';
import { I18nProvider } from './providers/I18n';
import config from './config';
import qs from 'query-string';
import { getSession } from './api/session';

import './App.css';

// todo html attr lang with query option

function App() {

    const [session, setSession] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [lang, setLang] = useState('en');
    const { t } = useTranslation();

    useEffect(() => {
        const params = qs.parse(window.location.search);
        if (params.id && params.token) {
            getSession({
                url: config.apiUrl,
                id: params.id,
                token: params.token,
                onSuccess: (session) => {
                    setSession(session);
                    setLoading(false);
                },
                onError: (err) => {
                    setError(err);
                    setLoading(true);
                }
            });
        } else {
            setError({
                type: 'MISSING_PARAMS',
                message: t('MISSING_PARAMS'),
            });
        }
        setLang(params.language || 'en');
    }, []);

    // todo intl bummer message
    return (
        <MantineProvider withGlobalStyles withNormalizeCSS>
            <I18nProvider language={lang}>
                <AppShell
                    padding={0}
                    header={<Header height={60} p="xs" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img height={35} src={`${config.cdnUrl}/assets/logos/Logo-MR-Bleu.png`} alt="logo"/>
                    </Header>}
                >
                    { loading && <Center sx={{ padding: '50px 0' }}>
                        <Loader sx={{ marginRight: 10 }} />
                        <Text>{ t('LOADING') }</Text>
                    </Center> }
                    { session && <UploadComponent
                        api={{
                            url: config.apiUrl,
                            lifeCycle: 'Expire',
                        }}
                        uploadSession={session}
                        {...session.options}
                        language={lang}
                    /> }
                    { error && <Box p="md">
                        <Alert title={t('ERROR_TITLE')} color="red">
                            { error.message }
                        </Alert>
                    </Box> }
                </AppShell>
            </I18nProvider>
        </MantineProvider>
    );
}

export default App;
