import {createContext, useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// intl init
import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import en from '../../translations/en.json';
import fr from '../../translations/fr.json';

i18next
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            fr: { translation: fr },
            en: { translation: en }
        },
        fallbackLng: "en",
        saveMissing: true,
        missingKeyHandler: (lngs, ns, key, fallbackValue, updateMissing, options) => {
            console.warn(`i18n Missing key: ${key}`, { lngs, ns, key, fallbackValue, updateMissing, options });
        },
    });

const I18nContext = createContext(null);

export const useI18n = () => useContext(I18nContext);

export function I18nProvider({ children, language }) {
    const { t, i18n } = useTranslation('translation');

    useEffect(() => {
        i18n.changeLanguage(language);
    }, []);

    return (
        <I18nContext.Provider
            value={{i18n, t}}
        >
            {children}
        </I18nContext.Provider>
    );
}

I18nProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    language: PropTypes.string.isRequired,
};
